<template>
  <v-card class="accounts-work_queues">
    <v-card-title>
      Disputes by reasons
    </v-card-title>
    <graph
      width="400px"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
import Graph from '@/components/graph.vue'
import _ from 'lodash'

export default {
  components: {
    Graph
  },
  created () {
    this.generateData()
  },
  //  computed: {
  //   items () {
  //     var items = this.$store.getters.disputes

  //     var r =  _(this.items)
  //     .groupBy(i => i.disputeReason)

  //   }

  // },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'donut'
        },
        labels: [],
        stroke: {
          width: 0
        }
      },
      series: []
    }
  },
  methods: {
    generateData () {
      this.series = []
      this.chartOptions.labels = []
      _(this.$store.getters.disputes)
        .groupBy(x => x.disputeReason.name)
        .map((value, key) => {
          this.series.push(value.length)
          this.chartOptions.labels.push(key)
          const z = { disputeReason: key, disputes: value.length }
          return z
        })
        .value()
    }
  }
}
</script>
